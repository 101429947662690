<p *ngIf="isOpen !== undefined" class="result-list-deadline">
  <span *ngIf="isOpen === true">
    {{'common.open' | translate}}:&nbsp;<span [ngClass]="'bold deadline-' + textColorClassOpeningHours">{{nextOpenClosedEventText}}</span>
  </span>

  <span *ngIf="isOpen === false">
    {{'common.closed' | translate}}:&nbsp;<span [ngClass]="'bold deadline-' + textColorClassOpeningHours">{{nextOpenClosedEventText}}</span>
  </span>
</p>

<p *ngIf="deadLineText" class="result-list-deadline">
  <span>{{deadLineText}}&nbsp;</span>
  <span class="bold" [class.deadline-danger]="deadlineStillOk === false" [class.deadline-success]="deadlineStillOk === true">{{deadLineTime}}</span>
  <button class="info-button ml-1" [ngbPopover]="'common.deadlineinfo' | translate" (click)="onInfoClick($event)">
    <i class="pi pi-info" aria-hidden="true"></i>
  </button>
</p>